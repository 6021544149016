<template>
  <v-container fluid class="content-wrapper mb-4">
    <div class="d-flex flex-row justify-start align-center">
      <h4 class="fw-600 text-mb-2">{{ $t("SigninTeamPurchase.title") }}</h4>
      <v-btn
        v-if="!modeSetting"
        icon
        class="ml-3 text-mb-2"
        @click="initAll()"
        :class="loadingTeamPurchaseData && 'animate__spining'"
        :disabled="loadingTeamPurchaseData"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <v-row row wrap>
      <div class="col-12">
        <div class="card" style="height: auto;">
          <div v-if="!modeSetting" class="card-header">
            <h4 class="card-title">
              <b>{{ $t("SigninTeamPurchase.team_purchase_duration") }}</b>
            </h4>
          </div>
          <div v-else-if="modeSetting" class="card-header">
            <h4 class="card-title">
              <b>{{ $t("SigninTeamPurchase.label") }}</b>
            </h4>
          </div>
          <div
            v-if="!modeSetting && loadingTeamPurchaseData"
            class="card-content"
            style="padding: 0 1.5rem 1.5rem 1.5rem !important; display: grid"
          >
            <v-skeleton-loader type="text@5"></v-skeleton-loader>
          </div>
          <div
            v-else-if="!modeSetting && !loadingTeamPurchaseData"
            class="card-content"
            style="padding: 0 1.5rem 1.5rem 1.5rem !important; display: grid"
          >
            <div class="mb-5 d-flex flex-row align-center justify-start">
              <div class="mr-3" style="width: 1.6rem">
                <v-img
                  :src="require('../../../assets/icons/teampurchase-icon/Filled 1.svg')"
                  contain
                  class="icon-tempurchase-ui"
                />
              </div>
              <span>
                <span class="text-bold">{{
                  $t("SigninTeamPurchase.least_min_stay")
                }}</span>
                {{ active ? minimum_customer || 5 : 0 }}
                {{ $t("SigninTeamPurchase.unit_least_min_stay") }}
              </span>
            </div>

            <div class="mb-5 d-flex flex-row align-center justify-start">
              <div class="mr-3" style="width: 1.6rem">
                <v-img
                  :src="require('../../../assets/icons/teampurchase-icon/Filled 2.svg')"
                  contain
                  class="icon-tempurchase-ui"
                />
              </div>
              <span>
                <span class="text-bold">{{ $t("SigninTeamPurchase.used_stay") }}</span>
                {{ countCustomer || 0 }}
                {{ $t("SigninTeamPurchase.unit_least_min_stay") }}
              </span>
            </div>

            <div class="mb-5 d-flex flex-row align-center justify-start">
              <div class="mr-3" style="width: 1.6rem">
                <v-img
                  :src="require('../../../assets/icons/teampurchase-icon/Filled 3.svg')"
                  contain
                  class="icon-tempurchase-ui"
                />
              </div>
              <span>
                <span class="text-bold">{{
                  $t("SigninTeamPurchase.discount_percent")
                }}</span>
                {{ active ? discount_percent || 20 : 0 }}
                {{ $t("SigninTeamPurchase.unit_percent") }}
              </span>
            </div>

            <div class="mb-5 d-flex flex-row align-center justify-start">
              <div class="mr-3" style="width: 1.6rem">
                <v-img
                  :src="require('../../../assets/icons/teampurchase-icon/Filled 4.svg')"
                  contain
                  class="icon-tempurchase-ui"
                />
              </div>
              <span>
                <span class="text-bold">{{ $t("SigninTeamPurchase.scope_days") }}</span>
                {{ date_for_participation || 0 }}
                {{ $t("SigninTeamPurchase.unit_days") }}
              </span>
            </div>

            <div
              v-if="remainDayOut"
              class="mb-3 d-flex flex-row align-center justify-start"
            >
              <div class="mr-3" style="width: 1.6rem">
                <v-img
                  :src="require('../../../assets/icons/teampurchase-icon/Filled 2.svg')"
                  contain
                  class="icon-tempurchase-ui"
                />
              </div>
              <span>
                <span class="text-bold mr-3">{{
                  $t("SigninTeamPurchase.remain_days")
                }}</span>
                <span class="text-error">{{
                  $t("SigninTeamPurchase.expired_campaign")
                }}</span>
              </span>
            </div>

            <div
              v-else-if="!remainDayOut"
              class="mb-3 d-flex flex-row align-center justify-start"
            >
              <div class="mr-3" style="width: 1.6rem">
                <v-img
                  :src="require('../../../assets/icons/teampurchase-icon/Filled 5.svg')"
                  contain
                  class="icon-tempurchase-ui"
                />
              </div>
              <span>
                <span class="text-bold">{{ $t("SigninTeamPurchase.remain_days") }}</span>
                {{ remainDay }}
                {{ $t("SigninTeamPurchase.unit_days") }} {{ remainHour }}
                {{ $t("SigninTeamPurchase.unit_hours") }} {{ remainMinute }}
                {{ $t("SigninTeamPurchase.unit_minutes") }} {{ remainSecond }}
                {{ $t("SigninTeamPurchase.unit_seconds") }}
              </span>
            </div>
          </div>
          <div
            v-else-if="modeSetting"
            class="card-content"
            style="padding: 0 1.5rem 1.5rem 1.5rem !important"
          >
            <b>{{ $t("SigninTeamPurchase.enable_sale") }}</b>
            <v-radio-group row v-model="active" @change="resetActive()">
              <div class="d-flex flex-row align-center justify-start">
                <v-radio
                  :label="$t('SigninTeamPurchase.want')"
                  :value="true"
                  color="#1e9ff2"
                ></v-radio>
              </div>
              <div class="d-flex flex-row align-center justify-start ml-3">
                <v-radio
                  :label="$t('SigninTeamPurchase.dontwant')"
                  :value="false"
                  color="#1e9ff2"
                ></v-radio>
              </div>
            </v-radio-group>
            <div v-if="active == true">
              <div v-if="active || (remainDayOut && oldTeamPurchaseData.active)">
                <b>{{ $t("SigninTeamPurchase.minimum_trading") }}</b>
                <div class="row mt-6" style="padding-bottom: 2rem;">
                  <div class="col-md-5">
                    <input type="number" v-model="minimum_customer" id="minimum_customer" name="minimum_customer" class="form-control" style="min-height: 3rem;" placeholder="0" min="5" max="10" @keypress="isNumber($event)" @focus="getfocus('minimum_customer')" @change="getfocus('minimum_customer')" @mouseleave="mouseOver('minimum_customer')"/>
                  </div>
                </div>
                <b>{{ $t("SigninTeamPurchase.discount_percent") }}</b>
                <div class="row mt-6">
                  <div class="col-md-5">
                    <v-slider
                      v-model="discount_percent"
                      :tick-labels="discountLabels"
                      :max="95"
                      :min="20"
                      step="5"
                      thumb-label="always"
                      color="#1e9ff2"
                      track-color="#828282"
                    ></v-slider>
                  </div>
                </div>
                <hr />
                <b>{{ $t("SigninTeamPurchase.days_join_group") }}</b>
                <div class="row my-2">
                  <div class="form-group col-md-3">
                    <div
                      class="dropdown select"
                      style="width: 18rem"
                      @click="getfocus('date_for_participation')"
                      @mouseleave="mouseOver('date_for_participation')"
                    >
                      <a id="date_for_participation">
                        <v-select
                          v-model="date_for_participation"
                          :items="itemsDateParticipation"
                          item-text="name"
                          item-value="value"
                          label
                          :placeholder="$t('SignupStep.select_one')"
                          :no-data-text="$t('SignupStep.nodata')"
                          color="#1e9ff2"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                          :disabled="active == false"
                        >
                          <template v-slot:item="data">
                            <v-list-item-content
                              @mouseenter="getfocus('date_for_participation')"
                              @mouseleave="mouseOver('date_for_participation')"
                            >
                              <v-list-item-title
                                v-html="data.item.name"
                                class="title-signup-sj"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <h4 class="card-title">
              {{ $t("SigninTeamPurchase.set_discount_level") }}
            </h4>
            <b>{{ $t("SigninTeamPurchase.discount_level_share") }}</b>-->
            <!-- <div class="mt-2">
              <v-radio-group v-model="enable_level">
                <div class="d-flex flex-row align-center justify-start">
                  <v-radio
                    :label="$t('SigninTeamPurchase.dontwant')"
                    :value="false"
                    color="#1e9ff2"
                  ></v-radio>
                </div>
                <div class="d-flex flex-row align-center justify-start mt-3">
                  <v-radio
                    :label="$t('SigninTeamPurchase.want')"
                    :value="true"
                    color="#1e9ff2"
                  ></v-radio>
                </div>
              </v-radio-group>
              <div class="col-12">
                <div class="row">
                  <label
                    class="col-auto label-control align-self-center"
                    for="userinput2"
                    >{{ $t("SigninTeamPurchase.share_discount") }}</label
                  >
                  <div class="col-md-3">
                    <div
                      class="dropdown select"
                      @click="getfocus('number_of_level')"
                      @mouseleave="mouseOver('number_of_level')"
                    >
                      <a id="number_of_level">
                        <v-select
                          v-model="number_of_level"
                          :items="itemsLevel"
                          label=""
                          :placeholder="$t('SignupStep.select_one')"
                          :no-data-text="$t('SignupStep.nodata')"
                          color="#1e9ff2"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                          :disabled="!enable_level"
                          @change="setupDiscountLevelFunc()"
                        >
                          <template v-slot:item="data">
                            <v-list-item-content
                              @mouseenter="getfocus('number_of_level')"
                              @mouseleave="mouseOver('number_of_level')"
                            >
                              <v-list-item-title
                                v-html="data.item"
                                class="title-signup-sj"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </a>
                    </div>
                  </div>
                  <label
                    class="col-auto label-control align-self-center"
                    for="userinput2"
                    >{{ $t("SigninTeamPurchase.level") }}</label
                  >
                </div>
              </div>
              <div v-if="number_of_level" class="col-12 my-2">
                <div class="row p-1">
                  <p>
                    {{ $t("SigninTeamPurchase.voucher_many") }}
                  </p>
                </div>
                <div
                  v-for="(item, index) in itemsDiscountLevel"
                  :key="index"
                  class="row"
                >
                  <b
                    class="
                      col-md-2 col-xl-1
                      label-control
                      align-self-center
                      pt-2
                      mb-0
                    "
                    for="userinput2"
                    >{{ $t("SigninTeamPurchase.level_no") }} {{ index + 1 }}</b
                  >
                  <div class="col-md-3 col-xl-2 col-sm-12 col-12">
                    <label
                      class="label-control align-self-center"
                      for="userinput2"
                      >{{ $t("SigninTeamPurchase.level_start") }}</label
                    >
                    <input
                      type="number"
                      min="0"
                      :max="item.end"
                      @keypress="isNumber($event)"
                      :id="`start_${index}`"
                      class="form-control"
                      style="min-height: 3rem"
                      placeholder="0"
                      name=""
                      v-model="item.start"
                      @focus="getfocus(`start_${index}`)"
                      @change="getfocus(`start_${index}`)"
                      @mouseleave="mouseOver(`start_${index}`)"
                    />
                  </div>
                  <div class="col-md-3 col-xl-2 col-sm-12 col-12">
                    <label
                      class="label-control align-self-center"
                      for="userinput2"
                      >{{ $t("SigninTeamPurchase.level_to") }}</label
                    >
                    <input
                      type="number"
                      :min="item.min"
                      @keypress="isNumber($event)"
                      :id="`end_${index}`"
                      class="form-control"
                      style="min-height: 3rem"
                      placeholder="0"
                      name=""
                      v-model="item.end"
                      @focus="getfocus(`end_${index}`)"
                      @change="getfocus(`end_${index}`)"
                      @mouseleave="mouseOver(`end_${index}`)"
                    />
                  </div>
                  <div class="col-10 col-md-2 col-xl-1 col-sm-12">
                    <label
                      class="label-control align-self-center"
                      for="userinput2"
                      >{{ $t("SigninTeamPurchase.level_discount") }}</label
                    >
                    <input
                      type="number"
                      min="0"
                      max="100"
                      @keypress="isNumber($event)"
                      :id="`discount_${index}`"
                      class="form-control"
                      style="min-height: 3rem"
                      placeholder="0"
                      name=""
                      v-model="item.discount_percent"
                      @focus="getfocus(`discount_${index}`)"
                      @change="getfocus(`discount_${index}`)"
                      @mouseleave="mouseOver(`discount_${index}`)"
                    />
                  </div>
                  <label
                    class="
                      col-auto col-md-2 col-xl-1
                      label-control
                      align-self-center
                      mb-0
                      px-0
                    "
                    style="padding-top: 1.5rem !important"
                    for="userinput2"
                    >%</label
                  >
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>

      <div class="col-md-12 py-3">
        <div
          v-if="
            !modeSetting &&
            (remainDayOut || !oldTeamPurchaseData.active) &&
            !loadingTeamPurchaseData
          "
          class="row"
        >
          <div class="col-md-12">
            <v-btn
              outlined
              class="btn-primary"
              style="min-height: 2.625rem; min-width: 7.5rem"
              dark
              @click="changeSettingModeFunc()"
              >{{ $t("SigninTeamPurchase.btn_setting") }}</v-btn
            >
          </div>
        </div>
        <div v-else-if="modeSetting" class="row">
          <div class="col-md-12 d-flex flex-row align-center justify-start">
            <v-btn
              outlined
              class="btn-primary"
              style="min-height: 2.625rem; min-width: 7.5rem"
              dark
              @click="saveTeamPurchase()"
              >{{ $t("SigninTeamPurchase.btn_save") }}</v-btn
            >
            <v-btn
              outlined
              class="btn-warning ml-3"
              style="min-height: 2.625rem; min-width: 7.5rem"
              dark
              @click="initAll()"
              >{{ $t("SigninTeamPurchase.btn_back") }}</v-btn
            >
          </div>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninTeamPurchaseScreen",
  metaInfo: {
    title: "Team purchase",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    value_element: "",
    propertyData: {},
    active: null,
    // active_app: null,
    slide_style: { label: "track-color", val: 75, color: "green lighten-1" },
    minimum_customer: null,
    customerLabels: [],
    discount_percent: null,
    discountLabels: [],
    date_for_participation: null,
    itemsDateParticipation: [],
    enable_level: null,
    number_of_level: null,
    itemsLevel: [],
    itemsDiscountLevel: [],
    oldTeamPurchaseData: {},
    modeSetting: false,
    countCustomer: 0,
    countDate: 0,
    remainDay: 0,
    remainHour: 0,
    remainMinute: 0,
    remainSecond: 0,
    remainDayOut: false,
    count_timer: null,
    loadingTeamPurchaseData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-blue-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-blue-focus");
        element.classList.add("border-blue-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-blue-focus");
        element.classList.remove("border-blue-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    resetActive() {
      const self = this;
      if (self.active == false) self.date_for_participation = undefined;
    },
    initAll() {
      const self = this;
      self.customerLabels = ["5", "", "", "", "", "10"];
      self.discountLabels = ["20%", "", "", "", "", "", "50%"];
      self.itemsDateParticipation = [
        {
          name: `1 ${self.$t("SigninTeamPurchase.days")}`,
          value: 1,
        },
        {
          name: `2 ${self.$t("SigninTeamPurchase.days")}`,
          value: 2,
        },
        {
          name: `3 ${self.$t("SigninTeamPurchase.days")}`,
          value: 3,
        },
      ];
      self.itemsLevel = [1, 2, 3, 4, 5];
      self.modeSetting = false;
      self.initPropertyData();
    },
    async initPropertyData() {
      const self = this;
      self.loadingTeamPurchaseData = true;
      self.remainDayOut = false;
      var temp = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        EventBus.$emit("refreshAppbarProperty");
        self.propertyData = temp;
        // console.log("property: ", temp, temp?.team_purchase);
        // console.log("team purchase: ", temp?.team_purchase);
        self.oldTeamPurchaseData = temp?.team_purchase || null;
        self.active =
          temp?.team_purchase?.active !== null ? temp?.team_purchase?.active : null;
        self.minimum_customer =
          temp?.team_purchase?.minimum_customer !== null
            ? temp?.team_purchase?.minimum_customer
            : null;
        self.discount_percent =
          temp?.team_purchase?.discount_percent !== null
            ? temp?.team_purchase?.discount_percent
            : null;
        self.date_for_participation =
          temp?.team_purchase?.date_for_participation !== null
            ? temp?.team_purchase?.date_for_participation
            : null;
        self.enable_level =
          temp?.team_purchase?.enable_level !== null
            ? temp?.team_purchase?.enable_level
            : null;
        self.number_of_level =
          temp?.team_purchase?.number_of_level !== null
            ? temp?.team_purchase?.number_of_level
            : null;
        self.itemsDiscountLevel =
          temp?.team_purchase?.discount_level !== null
            ? temp?.team_purchase?.discount_level
            : [];
        self.countCustomer =
          temp?.team_purchase?.active_customer !== null
            ? temp?.team_purchase?.active_customer
            : 0;
        self.countDate =
          temp?.team_purchase?.date_of_countdown !== null
            ? temp?.team_purchase?.date_of_countdown
            : null;

            if (temp?.team_purchase?.active_app) {
              // var expireDate = moment(temp?.team_purchase?.date_of_countdown_app)
              //   .add(self.date_for_participation || 0, "day")
              //   .format("YYYY-MM-DD");
              var currentDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
              var countDate = new Date(
                new Date(
                  moment(temp?.team_purchase?.date_of_countdown_app).add(
                    self.date_for_participation || 0,
                    "day"
                  )
                ).setUTCHours(0, 0, 0, 0)
              );
              if (countDate < currentDate) {
                self.remainDayOut = true;
                self.updateTimeoutAppFunc();
              } else {
                self.remainDayOut = false;
                // console.log(countDate, currentDate, expireDate);

                var compareDate = new Date(temp?.team_purchase?.date_of_countdown_app);
                compareDate.setDate(compareDate.getDate() + self.date_for_participation || 0);

                self.count_timer = setInterval(() => {
                  self.timeBetweenDates(compareDate);
                }, 1000);
              }
            } else {
              if (temp?.team_purchase?.date_of_countdown_app == null) {
                self.remainDay = self.date_for_participation || 0;
                self.remainHour = 0;
                self.remainMinute = 0;
                // var currentDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
                // var countDate = new Date(
                //   new Date(
                //     moment(temp?.team_purchase?.date_of_countdown_app).add(
                //       self.date_for_participation || 0,
                //       "day"
                //     )
                //   ).setUTCHours(0, 0, 0, 0)
                // );
                // if (countDate < currentDate) {
                //   self.remainDayOut = true;
                //   self.updateTimeoutAppFunc();
                // } else {
                //     self.remainDay = self.date_for_participation || 0;
                //     self.remainHour = 0;
                //     self.remainMinute = 0;
                // }
              } else {
                self.remainDayOut = true;
                self.updateTimeoutAppFunc();
              }
            }

        setTimeout(() => {
          self.loadingTeamPurchaseData = false;
        }, 1000);
      }
    },
    timeBetweenDates(toDate) {
      const self = this;
      var dateEntered = toDate;
      var now = new Date();
      var difference = dateEntered.getTime() - now.getTime();

      if (difference <= 0) {
        // Timer done
        clearInterval(self.count_timer);
        self.remainDayOut = true;
        self.updateTimeoutAppFunc();
      } else {
        var seconds = Math.floor(difference / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);

        hours %= 24;
        minutes %= 60;
        seconds %= 60;

        self.remainDay = days;
        self.remainHour = hours;
        self.remainMinute = minutes;
        self.remainSecond = seconds;
      }
    },
    gobackFunc() {
      EventBus.$emit("loadingPage");
      EventBus.$emit("goBackPage");
    },
    setupDiscountLevelFunc() {
      const self = this;
      var temp = [];
      if (self.enable_level && self.number_of_level) {
        for (var i = 0; i < self.number_of_level; i++) {
          temp.push({
            start: null,
            end: null,
            discount_percent: null,
          });
        }
      }
      self.itemsDiscountLevel = temp;
    },
    changeSettingModeFunc() {
      const self = this
      self.modeSetting = true
      console.log(self.remainDayOut, self.oldTeamPurchaseData.active);
      if (self.remainDayOut) self.active = false
    },
    saveTeamPurchase() {
      const self = this;
      EventBus.$emit("loadingtillend");

      const obj = {
        active: self.active,
        minimum_customer: self.active ? self.minimum_customer : null,
        discount_percent: self.active ? self.discount_percent : null,
        date_for_participation: self.active ? self.date_for_participation : null,
        enable_level: self.active ? self.enable_level : null,
        number_of_level: self.active ? self.number_of_level : null,
        discount_level: self.active ? self.itemsDiscountLevel || [] : [],
        date_of_countdown: self.active ? new Date() : null,
        date_of_countdown_app: "",
      };

      var checkPassDiscountLevel = true;
      var checkPassDiscountLevelMore = false;

      // console.log("obj: ", obj);

      if (obj?.active == false) {
        self.saveTeamPurchaseFunc(obj);
      } else if (
        obj?.active &&
        obj?.minimum_customer &&
        obj?.discount_percent &&
        ((obj?.active == true && obj?.date_for_participation) ||
          (!obj?.active == true && !obj?.date_for_participation))
        //   && obj?.enable_level !== null
      ) {
        if (obj?.enable_level && obj?.number_of_level) {
          obj?.discount_level?.map((el) => {
            if (!el?.start || !el?.end || !el?.discount_percent) {
              checkPassDiscountLevel = false;
              checkPassDiscountLevelMore = false;
            } else if (el?.start > el?.end) {
              checkPassDiscountLevel = false;
              checkPassDiscountLevelMore = true;
            }
          });

          if (checkPassDiscountLevel) {
            self.saveTeamPurchaseFunc(obj);
          } else {
            EventBus.$emit("endloading");
            swal(
              self.$t("Alert.warn_title"),
              checkPassDiscountLevelMore
                ? self.$t("SigninTeamPurchase.endshouldmorethanstart")
                : self.$t("Alert.fillinfo"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          }
        } else self.saveTeamPurchaseFunc(obj);
      } else {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    async saveTeamPurchaseFunc(obj) {
      const self = this;
      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/team_purchase?property_id=${self?.propertyID}`,
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 1000,
            }
          );
          self.initAll();
          EventBus.$emit("refreshAppbarProperty");
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error);
        swal(
          self.$t("Alert.error_title"),
          error?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.error_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        EventBus.$emit("endloading");
      }
    },
    checkChangeData() {
      const self = this;
      var cantgonextroute = true;

      if (
        self.active !== self?.oldTeamPurchaseData.active ||
        self.minimum_customer !== self?.oldTeamPurchaseData.minimum_customer ||
        self.discount_percent !== self?.oldTeamPurchaseData.discount_percent ||
        self.date_for_participation !==
          self?.oldTeamPurchaseData.date_for_participation ||
        self.enable_level !== self?.oldTeamPurchaseData.enable_level
      ) {
        cantgonextroute = true;
      } else cantgonextroute = false;

      return cantgonextroute;
    },
    async updateTimeoutAppFunc() {
      const self = this;

      const obj = {
        active_app: false,
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/team_purchase?property_id=${self?.propertyID}`,
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
@import "../../../styles/signin/holiday.css";
@import "../../../styles/signin/rateplan.css";
@import "../../../styles/signin/promotion.css";

#promotioninactive tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  background-color: #6b6f82 !important;
}
</style>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent !important;
  border-bottom: none !important;
}

.border-pink-focus {
  border-color: var(--pink) !important;
}

.btn-group {
  border: 1px solid #666ee8;
  max-width: fit-content;
  border-radius: 0px;
  // border-bottom-right-radius: 0px;
  background-color: #fff !important;
  color: #666ee8 !important;
}

.btn-group-active {
  border: 1px solid #666ee8;
  max-width: fit-content;
  border-radius: 0px;
  // border-bottom-right-radius: 0px;
  background-color: #666ee8 !important;
  color: #fff !important;
}

.icon-tempurchase-ui {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
</style>
